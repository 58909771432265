import React, { useEffect, useState } from 'react'
import { EuiPageTemplate, 
  EuiButton, 
  EuiEmptyPrompt, 
  EuiFlexGroup, 
  EuiFlexItem, 
  EuiPageHeader, 
  EuiPageHeaderSection, 
  EuiTitle, 
  EuiImage, 
  EuiSwitch,
  EuiBottomBar} from '@elastic/eui';
import axios from 'axios';
import APIEndpoint from './Api';
import MainContent from './components/MainContent';

const env = "prod"
const severityLevels = ["HIGH", "MEDIUM", "LOW"]

function App() {

  const [reportData, changeReportData] = useState()
  const [urlPrompt, changeUrlPrompt] = useState(false)
  const [isLoading, changeIsLoading] = useState(false)
  const [theme, changeTheme] = useState(window.localStorage.getItem("ls-theme") ? window.localStorage.getItem("ls-theme") : "light")

  useEffect(()=>{    
      window.localStorage.setItem("ls-theme", theme)
      
      let cssLink = document.createElement("link")
      cssLink.setAttribute("id", "ls-theme")
      cssLink.setAttribute("rel", "stylesheet")

      if (theme==="dark"){                
        cssLink.setAttribute("href", "css/eui_theme_dark.css")
      } else if (theme==="light"){                
        cssLink.setAttribute("href", "css/eui_theme_light.css")
      }

      if (document.querySelector("#ls-theme")) {
        document.querySelector("#ls-theme").remove()
      }
      document.querySelector("head").appendChild(cssLink)
    
  }, [theme])  
  
  useEffect(()=>{
    const curPath = window.location.pathname
    if (curPath.startsWith("/share-target/")){
      const parsedUrl = new URL(window.location);

      // searchParams.get() will properly handle decoding the values.
      let sharedTitle = parsedUrl.searchParams.get('title') || "";
      let sharedText = parsedUrl.searchParams.get('text') || "";
      let sharedUrl = parsedUrl.searchParams.get('url') || "";

      // do validation since shared info is not guaranteeed to be in the right fields
      let url = null      
      if (sharedUrl.startsWith("http:") || sharedUrl.startsWith("https:")){
        url = sharedUrl
      } else if (sharedTitle.startsWith("http:") || sharedTitle.startsWith("https:")){
        url = sharedTitle
      } else if (sharedText.startsWith("http:") || sharedText.startsWith("https:")){
        url = sharedText
      }

      let text = null
      if (!sharedText.startsWith("http:") && !sharedText.startsWith("https:")){
        text = sharedText
      }      

      alert(url, text)

    }
  },[])

  const handleHash = () => {
    let hash = window.location.hash.substring(1)
    if (hash && hash !== "" && hash !== "#"){
      changeIsLoading(true)
      changeUrlPrompt(false)
      axios.get(`${APIEndpoint(env).getReport}/${hash}`).then(resp => {
        let maxResults = {}
        severityLevels.forEach(severity => {
          let curMax = 0
          if (resp.data.googleKeywordsForecast[severity]){
            resp.data.googleKeywordsForecast[severity].forEach(element => {
              if (element[1]>curMax){
                curMax = element[1]
              }
            });
          }          
          maxResults[severity]=curMax
        })        

        changeIsLoading(false)
        changeReportData({
          data: resp.data, 
          max: maxResults
        })        

      }).catch(err=>{
        console.log(err);
        alert("The report is taking a while to generate. Please refresh the page or try again later.")
      })
    } 
  }
  

  useEffect(()=>{
    if (window.location.hash!=="" && window.location.hash!=="#"){
      handleHash()
    } else {
      changeUrlPrompt(true)
    } 
    window.onhashchange = handleHash  
  },[])  


  return (<>
  <EuiPageHeader alignItems="center" style={{
    marginTop: 15,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "1000px",
    width: "100%"
  }}>
    <EuiPageHeaderSection>
      <EuiFlexGroup direction="row" alignItems="center" responsive={false}>
        <EuiFlexItem>
          <EuiImage            
            size="m"
            alt="Logo"
            fullScreenIconColor="dark"
            src={theme==="light" ? "logoText.png" : "logoTextDark.png"}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          <EuiTitle size="l" style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
              marginRight: "auto"
            }}>
              <h1>Magic Keyword Selection</h1>
            </EuiTitle>
        </EuiFlexItem>                     
      </EuiFlexGroup>      
    </EuiPageHeaderSection>
    <EuiPageHeaderSection>
    </EuiPageHeaderSection>
  </EuiPageHeader>
    <EuiPageTemplate
      template="centeredBody"
      pageContentProps={{ paddingSize: 'none' }}  
    >
      <EuiEmptyPrompt
        title={ <MainContent 
            report={reportData} 
            urlPrompt={urlPrompt} 
            isLoading={isLoading} 
            onReset={()=>{
              changeUrlPrompt(true)
              changeReportData(null)
            }}
          />}
      />
    </EuiPageTemplate>
    <EuiBottomBar>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiButton color="ghost" size="s" iconType="help">
                  About Us
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiSwitch
                label={theme==="light" ? "Go to the dark side" : "Lighten the mood"}
                checked={theme==="light" ? false : true}
                onChange={()=>theme==="light" ? changeTheme("dark") : changeTheme("light")}
              />
            </EuiFlexItem>  
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>    
  </>
  );
}

export default App;
