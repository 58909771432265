import { EuiCard, EuiFlexItem, EuiIcon, EuiProgress, EuiIconTip } from '@elastic/eui'
import React from 'react'

const GoogleAnalysis = ({max=false, data=false}) => {
    if (!max){
        return
      }

    if (!data){
        return
    }

    return <>      
      <EuiFlexItem>
        <EuiCard
          layout="horizontal"
          icon={<EuiIcon size="xl" type={'search'} />}
          title={<>
            <span style={{marginRight: 10}}>Low Competition Suggestions</span>
            <EuiIconTip
            content={<>
              <p>Low competition keywords are also known as long-tail keywords. These keywords have a low cost per click in advertising.</p>
              <p style={{marginTop:5}}>In other words, focus on these if you want to have a good return on investment for your advertising and better chances of ranking high in your SEO</p>
              <p style={{marginTop:5}}>Traffic is certainly small for low competition keywords. However, they have the highest conversion rate</p>              
            </>}
            position="right"
            color="primary"
          />
          </>}
          description={<>
            {data["LOW"].map(elem=> <EuiProgress
              label={elem[0]}
              valueText={<span>{elem[1]} searches last month</span>}
              value={elem[1]}
              max={max['LOW']}
              color="primary"
              size="l"
            />)}
          </>}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          layout="horizontal"
          icon={<EuiIcon size="xl" type={'search'} />}
          title={<>
            <span style={{marginRight: 10}}>Medium Competition Suggestions</span>
            <EuiIconTip
            content={<>
              <p>Middle competition keywords are less specific than low competion keywords.</p>
              <p style={{marginTop:5}}>They are between low and high competion keywords. If you are after traffic and you have enough advertising budget, go for high competition keywords. However, if you are after profit and sales growth, focus more on low competition keywords.</p>          
            </>}
            position="right"
            color="primary"
          />
          </>}
          description={<>
            {data["MEDIUM"].map(elem=> <EuiProgress
              label={elem[0]}
              valueText={<span>{elem[1]} searches last month</span>}
              value={elem[1]}
              max={max['MEDIUM']}
              color="primary"
              size="l"
            />)}
          </>}          
        />
      </EuiFlexItem>      
      <EuiFlexItem>
        <EuiCard
          layout="horizontal"
          icon={<EuiIcon size="xl" type={'search'} />}
          title={<>
            <span style={{marginRight: 10}}>High Competition Suggestions</span>
            <EuiIconTip
            content={<>
              <p>High competition keywords or “head keywords” have a high cost per click, meaning they are good for advertising but not for SEO.
                These keywords are extremely competitive. You can use and bid for them, but it will be harder to rank for these keywords.</p>
              <p style={{marginTop:5}}>In other words, only use these keywords if you'd like to attract high value advertising</p>
            </>}
            position="right"
            color="primary"
          />
          </>}
          description={<>
            {data["HIGH"].map(elem=> <EuiProgress
              label={elem[0]}
              valueText={<span>{elem[1]} searches last month</span>}
              value={elem[1]}
              max={max['HIGH']}
              color="primary"
              size="l"
            />)}
          </>}
        />
      </EuiFlexItem>
    </>
    
}

export default GoogleAnalysis