import { EuiCard, EuiFlexItem, EuiIcon, EuiIconTip } from '@elastic/eui'
import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';


function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className="custom-tooltip">
        <strong>{`${payload[0].value} tweets`}</strong>
      </div>
    );
  }

  return null;
}

const TwitterAnalysis = ({data=false}) => {
    
    if (!data){
        return
    }

    const graphData = data.tweets.map((elem,idx)=>{
      const daysAgo = data.tweets.length -idx  
      return {
        name: `${daysAgo} days ago`,
        tweets: elem,
      }
    })

    return <>
      <EuiFlexItem>
        <EuiCard
          layout="horizontal"
          icon={<EuiIcon size="xl" type={'search'} />}
          title={<>
            <span style={{marginRight: 10}}>{graphData.length}-day tweet volume</span>
            <EuiIconTip
            content={<>
              <p>The amount of tweets containing the keywords detected above over the past {graphData.length} days</p>
            </>}
            position="right"
            color="primary"
          />
          </>}
          description={<>
          
            <BarChart width={450} height={200} data={graphData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
              <Bar dataKey="tweets" fill="blue" />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />}/>
            </BarChart>
            
          </>}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiCard
          layout="horizontal"
          icon={<EuiIcon size="xl" type={'search'} />}
          title={<>
            <span style={{marginRight: 10}}>Twitter Keyword Suggestions</span>
            <EuiIconTip
            content={<>
              <p>Keywords suggested by Twitter.</p>
              <p style={{marginTop:5}}>These keywords are suggestions based on popular tweets. They can be used to improve your twitter audience engagement</p>          
            </>}
            position="right"
            color="primary"
          />
          </>}
          description={<code>{data.keywords.length > 0 ? data.keywords.join(", ") : "None"}</code>}
        />
      </EuiFlexItem>      
    </>
    
}

export default TwitterAnalysis