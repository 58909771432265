const APISettings = {
    "devEndpoint": "http://localhost:8000",
    "prodEndpoint": "https://backend.leksify.digital"
}

const APIEndpoint = (env) => {
    switch (env) {
        case "dev":
            return {
                getReport: `${APISettings.devEndpoint}/report`,
                getKeywordsForUrl: `${APISettings.devEndpoint}/extract-keywords/url`,
                getKeywordsForFile: `${APISettings.devEndpoint}/extract-keywords/file`,
            }
    
        default:
            return {
                getReport: `${APISettings.prodEndpoint}/report`,
                getKeywordsForUrl: `${APISettings.prodEndpoint}/extract-keywords/url`,
                getKeywordsForFile: `${APISettings.prodEndpoint}/extract-keywords/file`,
            }
    }
}

export default APIEndpoint