
import React, { useState, Fragment, useRef } from 'react';

import {
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer
} from '@elastic/eui';

const FileUpload = ({onFileChange}) => {
  const [files, setFiles] = useState([]);
  const [large] = useState(true);
  const filePickerRef = useRef();

  const onChange = (files) => {
      if (files.length > 0 && Array.from(files)[0].type!=="application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
          alert("Only Office .docx files are currently supported")
          setFiles([])
          filePickerRef.current.removeFiles()
      } else if (onFileChange){
        setFiles(files.length > 0 ? Array.from(files) : []);
        onFileChange(files.length > 0 ? Array.from(files) : []);
      }    
  };

  const renderFiles = () => {
    if (files.length > 0) {
      return (
        <>
          <h3>Files attached</h3>
            <ul>
            {files.map((file, i) => (
                <li key={i}>
                <strong>{file.name}</strong> ({file.type} bytes)
                </li>
            ))}
            </ul>
        </>
      );
    } else {
      return (
        <p>You may also upload up to one file for keyword extraction</p>
      );
    }
  };

  return (
    <Fragment>
      <EuiFlexGroup>
        <EuiFlexItem grow={2}>
          <EuiFilePicker
                ref={filePickerRef}
              id="asdf2"
              multiple="false"
              initialPromptText="Select or drag and drop multiple files"
              onChange={onChange}
              display={large ? 'large' : 'default'}
            />
          <EuiSpacer />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>            
            {renderFiles()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  );
};

export default FileUpload;